import { getCurrentInterestRateDetails } from 'common/interest-rate-components/base';
import Layout from 'common/layouts/Layout';
import { ButtonColor } from 'component-library/components/buttons/PrimaryButton';
import { PageWrapper } from 'component-library/components/wrappers/PageWrapper';
import { SEO } from 'components/SEO';
import { graphql, useStaticQuery } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { BottomCta } from 'page-components/partnerships/bottom-cta';
import { ComeToRightPlaceProtectPlus } from 'page-components/partnerships/come-to-right-place/ComeToRightPlaceProtectPlus';
import { Heading } from 'page-components/partnerships/heading';
import { Hero } from 'page-components/partnerships/hero';
import { RetirableSteps } from 'page-components/partnerships/retirable-steps';
import { Step } from 'page-components/partnerships/retirable-steps/Step';
import { promoterVerificationComplianceFooterFor } from 'page-components/partnerships/shared/content';
import { Featured, Footer } from 'page-components/shared';
import { FooterTemplate } from 'page-components/shared/footer';
import React, { FC } from 'react';
import { track } from 'utils/analytics';

interface GohealthProtectPlusProps {}

const PARTNER_NAME = 'GoHealth';

const TRACKING_PARAMS = {
  source: 'medicare',
  medium: 'landing-page',
  term: 'gohealth',
  campaign: 'gohealth',
  content: '',
};

const GoHealthRetirableLogos = (
  <StaticImage
    src="../../../page-components/partnerships/images/gohealth-retirable-logos.webp"
    alt="partnership logo"
    width={512}
  />
);

const HeroImage = (
  <StaticImage
    src="../../../page-components/partnerships/images/gohealth-income-protect.webp"
    alt="Retirable Income Protect+"
    width={512}
  />
);

const navigateToOnboarding = () => {
    track('Onboarding CTA Clicked');
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set('utm_source', 'medicare');
    urlParams.set('utm_campaign', 'gohealth');
    urlParams.has('utm_medium') || urlParams.set('utm_medium', 'landing-page');
    window.location.href = 'https://app.retirable.com/missions/sign-up?' + urlParams.toString();
}

const GohealthProtectPlus: FC<GohealthProtectPlusProps> = () => {
  const data = useStaticQuery(graphql`
    query SpendSaveProtectPlan {
      ...GetCurrentInterestRateDetails
    }
  `);
  const { formattedApy } = getCurrentInterestRateDetails(data);

  const childProps = {
    onCtaClick: navigateToOnboarding,
    defaultUtmParams: TRACKING_PARAMS,
    partnerName: PARTNER_NAME,
  };

  return (
    <Layout>
      <Heading
        {...childProps}
        ctaWithCaret
        ctaButtonText="Protect Your Income"
        headerImg={GoHealthRetirableLogos}
      />
      <PageWrapper
        background="linear-gradient(
          180deg,
          #EBF8F2 33%,
          #ffe8d8 90%,
          #FFCFAD 100%
        )"
      >
        <Hero
          {...childProps}
          wideGap
          ctaWithCaret
          heroImg={HeroImage}
          heroContent={{
            headline: 'Protect your retirement income.',
            headlineBody: '',
            headlineBodyBolded: 'SPEND CONFIDENTLY. SAVE MONTHLY. 24/7 PROTECTION.',
            listHeadline: 'Retirable Income Protect+ helps you:',
            bulletPoints: [
              'Protect your retirement income against inflation',
              'Protect your retirement income for a rainy day',
              'Protect your retirement income from fraud and money mistakes',
            ],
            ctaText: '',
            submitButtonText: 'Get Started',
          }}
        />
        <Featured />
        <RetirableSteps>
          <Step
            heading="Protect your income and keep your spending on track"
            body="Safe-to-spend tools keep your lifestyle and plan aligned while budgeting and spending check-ins ensure your retirement stability."
            image={<StaticImage src="../../../page-components/partnerships/images/spend-step.webp" alt="spend" />}
          />
          <Step
            heading={`Hedge against inflation by earning ${formattedApy} APY* on your cash savings`}
            body="Monthly cash sweeps help you build savings faster while our high-yield FDIC-insured account grows your wealth in retirement."
            image={<StaticImage src="../../../page-components/partnerships/images/save-step.webp" alt="save" />}
          />
          <Step
            heading="Protect your future with $1M in identity theft protection"
            body="$1 million in identity theft insurance with a suite of identity and credit monitoring tools, lost wallet assistance, and the ability to block spam calls."
            image={<StaticImage src="../../../page-components/partnerships/images/protect-step.webp" alt="protect" />}
          />
          <Step
            heading="One-on-one retirement plan guidance and monitoring"
            body="Get a personalized retirement spending and budgetary consultation with frequent check-ups to ensure your retirement stability."
            image={<StaticImage src="../../../page-components/partnerships/images/plan-step.webp" alt="plan" />}
          />
        </RetirableSteps>
        <ComeToRightPlaceProtectPlus />
        <BottomCta
          {...childProps}
          withForm={false}
          heading="Protect your peace of mind in retirement."
          subHeading="Register for Income Protect+ today!"
          ctaButtonText="Get Started"
          ctaButtonColor={ButtonColor.Teal}
        />
        <Footer
          template={FooterTemplate.LegalDisclaimerAndContacts}
          additionalFootnotes={[
            promoterVerificationComplianceFooterFor(PARTNER_NAME)
          ]}
          {...childProps}
        />
      </PageWrapper>
    </Layout>
  );
};

export default GohealthProtectPlus;

export const Head = () => (
  <SEO
    title={`${PARTNER_NAME} + Retirable Income Protect Plus`}
    description={`Learn more about how ${PARTNER_NAME} and Retirable are working together to help you protect your income in retirement.`}
  />
);
